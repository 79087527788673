import React, { useState } from "react";
import useClientDetails from "../hooks/useClientDetails";
import { DisplayShippingAccount } from "../../../types";
import { useDispatch } from "react-redux";
import { Col, H3, Popup, Row } from "@commonsku/styles";
import Form from "../../Form";
import { createDeleteShippingQuote, createUpdateShippingQuote } from "../../../actions/shipping";
import styled from "styled-components";

const Button = styled.a`
    &&& {
        float: right;
        margin-left: 1rem;
    }
`;

const DeleteButton = styled.a`
    float: right;
`;

const HeaderRow = styled(Row)`
    &&& {
        padding: 1rem 1.875rem 0.5rem;
    }
`;

export interface EditShippingAccountPopupProps {
    onClose: () => void
    shippingAccount: DisplayShippingAccount
}

export const EditShippingAccountPopup = ({
    onClose,
    shippingAccount,
}: EditShippingAccountPopupProps) => {
    const { couriers } = useClientDetails();
    const dispatch = useDispatch();
    const [courierId, setCourierId] = useState(shippingAccount.courier.id);
    const [accountNumber, setAccountNumber] = useState(shippingAccount.number);

    const onDelete = () => {
        dispatch(createDeleteShippingQuote(shippingAccount.id));
        onClose();
    }

    const onEdit = () => {
        dispatch(createUpdateShippingQuote(shippingAccount.id, {
            courier_id: courierId,
            account_number: accountNumber,
        }));
        onClose();
    }

    return (
        <Popup width="600px" height="auto" header={
            <HeaderRow>
                <Col>
                    <H3>
                        Edit Third Party Shipping Account
                    </H3>
                </Col>
            </HeaderRow>
        }>
            <div className="row" />
            <Form
                className="row popup-content form"
                onFieldChange={() => {}}
            >
                <div className="small-12 columns">
                    <div className="field row" />
                    <Form.Select
                        label="Courier"
                        field="courier_id"
                        options={couriers.map((courier) => ({
                            key: courier.id,
                            value: courier.label,
                        }))}
                        required={true}
                        withMarginBottom={true}
                        value={courierId}
                        onChange={setCourierId}
                    />
                    <Form.TextInput
                        label="Account Name"
                        field="account_name"
                        required={true}
                        value={shippingAccount.name}
                        disabled
                    />
                    <Form.TextInput
                        label="Account Number"
                        field="account_number"
                        required={true}
                        value={accountNumber}
                        onChange={setAccountNumber}
                    />
                    <Form.TextInput
                        label="Postal / Zip Code"
                        field="account_postal"
                        required={true}
                        value={shippingAccount.postal}
                        disabled
                    />
                </div>
                <div className="row">
                    <div className="small-12 columns">
                        <Button className="button" onClick={onEdit}>Save</Button>
                        <Button className="button" onClick={onClose}>Cancel</Button>
                        <DeleteButton className="button alert" onClick={onDelete}>Delete</DeleteButton>
                    </div>
                </div>
            </Form>
        </Popup>
    );
}
