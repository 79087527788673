import { DisplayClient } from "../../../types";
import React from "react";
import { Link } from "react-router-dom";
import {
  colors,
  Text,
  EditIcon,
  CopyIcon as BaseCopyIcon,
} from "@commonsku/styles";
import styled from "styled-components";
import { setEditingDetails, showPopup } from "../../../redux/clientDetails";
import { useDispatch } from "react-redux";
import { ViewAll } from "./ViewAll";
import { formatName } from "../utils";
import { useScrollPopup } from "../hooks/useScrollPopup";
import { findInOptions } from "../../../utils";
import useCompanyCommissionClientRates from "../../admin-new/hooks/useCompanyCommissionClientRates";
import { VerticalScrollIndicator } from "../ScrollIndicator";

const DetailsCardContainer = styled.div`
  width: 100%;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const HeaderText = styled(Text)`
  flex: 1;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  color: ${colors.neutrals[100]};
`;

const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: ${colors.teal[65]};
`;

const HeaderEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ClientGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  @media (0px <= width <= 800px) {
    grid-template-columns: 1fr;
  }

  @media (800px < width <= 920px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (920px < width <= 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1201px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const IndicatorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px !important;
`;

const ScrollBox = styled.div`
  height: 375px;
  width: 100%;
  overflow-y: scroll;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  min-width: 105px;
  gap: 7px;

  tr:hover > td {
    cursor: default;
    background-color: inherit;
  }
`;

const TagsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  min-width: 105px;
  gap: 7px;

  @media (min-width: 1201) {
    grid-column: span 2;
  }

  tr:hover > td {
    cursor: default;
    background-color: inherit;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.secondary4[80]};
  border-radius: 100px;
  padding: 0 8px 0 8px !important;

  font-size: 14px;
  font-weight: 400;
  color: white;
`;

const THeadText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutrals[100]};
  vertical-align: middle;
`;

const TbodyText = styled(Text)<{ $blue?: boolean }>`
  vertical-align: middle;
  font-weight: 400;
  font-family: "skufont-regular";
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.$blue ? colors.primary1[65] : colors.neutrals[90]};
`;

const ClientIcon = styled.img`
  vertical-align: middle;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 4px;
`;

const CopyIcon = styled(BaseCopyIcon)`
  vertical-align: middle;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 4px;
`;

export interface DetailsCardProps {
  client: DisplayClient;
}

export const DetailsCard = ({ client }: DetailsCardProps) => {
  const dispatch = useDispatch();
  const { scrollRef, handleScroll, scrolledToTop, scrolledToBottom } =
    useScrollPopup();

  const [rates] = useCompanyCommissionClientRates();
  const rateOptions = rates.map((rate) => ({
    label: `${rate.client_rate_label} (x${rate.multiplier})`,
    value: rate.commission_client_rate_id,
  }));

  return (
    <DetailsCardContainer>
      <Header>
        <HeaderText>Details</HeaderText>
        <HeaderButtons>
          <HeaderEditIcon
            onClick={() => {
              dispatch(setEditingDetails(true));
              dispatch(showPopup("view-details"));
            }}
          />
        </HeaderButtons>
        <HeaderButtons>
          <ViewAll onClick={() => dispatch(showPopup("view-details"))}>
            View All
          </ViewAll>
        </HeaderButtons>
      </Header>
      <IndicatorContainer>
        <VerticalScrollIndicator $direction="up" $visible={!scrolledToTop} />
        <ScrollBox ref={scrollRef} onScroll={handleScroll}>
          <ClientGrid>
            <DetailBox>
              <THeadText>Client Rep</THeadText>
              <div>
                {client.clientRep ? (
                  <>
                    {client.clientRep.avatarImagePath != null && (
                      <ClientIcon
                        src={client.clientRep.avatarImagePath.toString()}
                        alt=""
                      />
                    )}
                    <TbodyText $blue>
                      {client.clientRep.firstName} {client.clientRep.lastName}
                    </TbodyText>
                  </>
                ) : (
                  <TbodyText>No Client Rep</TbodyText>
                )}
              </div>
            </DetailBox>
            <DetailBox>
              <THeadText>Status</THeadText>
              <TbodyText>
                {client.status != null ? client.status.name : "No Status"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Default Terms</THeadText>
              <TbodyText>{client.defaultTerms.name}</TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Tax</THeadText>
              <TbodyText>{client.defaultTax.label}</TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Min Margin</THeadText>
              <TbodyText>{client.minMargin}</TbodyText>
            </DetailBox>
            <DetailBox>
              <div>
                <THeadText>Portal</THeadText>
                <CopyIcon
                  size="medium"
                  altText="Portal"
                  onClick={() =>
                    navigator.clipboard.writeText(client.portal.id)
                  }
                />
              </div>
              <TbodyText $blue>
                <Link to={"./portal/"} rel="opener noreferrer" target="_blank">
                  Main Portal
                </Link>
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Sales Target</THeadText>
              <TbodyText>${client.salesTarget}</TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Industry</THeadText>
              <TbodyText>{client.industry.name}</TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Currency</THeadText>
              <TbodyText>{client.defaultCurrency.name}</TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Parent Client</THeadText>
              <TbodyText $blue={client.parentClientId !== null}>
                {client.parentClientId !== null ? (
                  <Link
                    to={"./../" + client.parentClientId}
                    rel="opener noreferrer"
                    target="_blank"
                  >
                    {client.parentClientName}
                  </Link>
                ) : (
                  "No Parent Client"
                )}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Status</THeadText>
              <TbodyText>
                {client.status !== null ? client.status.name : "No Status"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Client Rate</THeadText>
              <TbodyText>
                {findInOptions(rateOptions, client.commissionClientRateId, {})[
                  "label"
                ] ?? "No Client Rate"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Entity Use Code</THeadText>
              <TbodyText>
                {client.avalaraEntityUseCode ?? "No Entity Use Code"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Quickbooks Integration</THeadText>
              <TbodyText>
                {client.qboCustomerRef ? "Mapped" : "Not Mapped"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Primary Contact</THeadText>
              <TbodyText>
                {client.primaryContact !== null
                  ? formatName(
                      client.primaryContact.firstName,
                      client.primaryContact.lastName
                    )
                  : "No Primary Contact"}
              </TbodyText>
            </DetailBox>
            <DetailBox>
              <THeadText>Email</THeadText>
              <TbodyText>
                {client.email !== null
                  ? client.email
                  : "None"
                }
              </TbodyText>
            </DetailBox>
            {client.phones.length > 0 && client.phones[0].number && (
              <DetailBox>
                <THeadText>Phone 1</THeadText>
                <TbodyText>{client.phones[0].number}</TbodyText>
              </DetailBox>
            )}
            <TagsBox>
              <THeadText>Tags</THeadText>
              <TagsContainer>
                {client.tags.map((tag) => (
                  <Tag>{tag.label}</Tag>
                ))}
              </TagsContainer>
            </TagsBox>
          </ClientGrid>
        </ScrollBox>
        <VerticalScrollIndicator
          $direction="down"
          $visible={!scrolledToBottom}
        />
      </IndicatorContainer>
    </DetailsCardContainer>
  );
};
