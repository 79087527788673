import React, { useMemo } from "react";
import { DisplayNote } from "../../../types";
import styled from "styled-components";
import { colors, Text } from "@commonsku/styles";
import { toTitleCase } from "../../../utils";
import { Link } from "react-router-dom";
import { formatRelativeDate } from "../utils";
import { UserAvatar } from "../UserAvatar";
import { ViewAll } from "./ViewAll";

const ActivityCardContainer = styled.div`
  width: 100%;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const HeaderText = styled(Text)`
  flex: 1;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  color: ${colors.neutrals[100]};
`;

const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding-right: 8px;
`;

const Activities = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActivityBody = styled.div`
  overflow: hidden;
`;

const ActivityText = styled(Text)<{ $blue?: boolean }>`
  display: block;
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
  font-family: "skufont-regular";
  line-height: 24px;
  color: ${(props) => (props.$blue ? colors.teal[65] : colors.neutrals[90])};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateText = styled(Text)`
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  font-family: "skufont-regular";
  line-height: 24px;
  width: max-content;
  color: ${colors.neutrals[70]};
`;

const ActivityRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export interface ActivityCardProps {
  activity: readonly DisplayNote[];
  limit?: number;
}

interface ActivityRowProps {
  activity: DisplayNote;
}

const ActivityRow = ({ activity }: ActivityRowProps) => {
  return (
    <ActivityRowContainer>
      <UserAvatar user={activity.author} />
      <ActivityBody>
        <ActivityText
          $blue
          dangerouslySetInnerHTML={{ __html: activity.message }}
        />
        <div>
          <DateText>
            {toTitleCase(activity.type)}
            {", "}
            {formatRelativeDate(activity.creationDate)}
          </DateText>
        </div>
      </ActivityBody>
    </ActivityRowContainer>
  );
};

export const ActivityCard = ({ activity, limit = 4 }: ActivityCardProps) => {
  const recentActivity = useMemo(
    () =>
      [...activity]
        .sort((a, b) => {
          return (
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
          );
        })
        .slice(0, limit),
    [activity, limit]
  );

  return (
    <ActivityCardContainer>
      <Header>
        <HeaderText>Activity</HeaderText>
        <HeaderButtons>
          <ViewAll>
            <Link to="./activity">View All</Link>
          </ViewAll>
        </HeaderButtons>
      </Header>
      <Activities>
        {recentActivity.map((note) => (
          <ActivityRow key={note.id} activity={note} />
        ))}
      </Activities>
    </ActivityCardContainer>
  );
};
