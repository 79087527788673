import { omit } from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ReportFilterValues } from "./config/types";
import {
  ReportFilterConfig,
  FILTER_STYLE_CONSTANTS,
} from "./config/ReportFilterConfig";
import { colors, DateRangePreset } from "@commonsku/styles";
import loadable, { LoadableComponent } from "@loadable/component";
import { useReportContext } from "../report_context";
import { DateRangePresetPivots } from "./components/DateRangePicker";

const { FILTER_ITEMS_GAP } = FILTER_STYLE_CONSTANTS;

interface FilterProps {
  config: ReportFilterConfig;
  queryValues: ReportFilterValues;
  onQueryChange: (values: ReportFilterValues) => void;
  isInMoreFilters?: boolean;
  dependsOnValues?: ReportFilterValues;
  isMulti?: boolean;
}

export interface FilterMainComponentProps
  extends Pick<FilterProps, "isMulti" | "dependsOnValues"> {
  values: ReportFilterValues;
  onChange: (values: ReportFilterValues) => void;
  placeholder?: string;
  style: React.CSSProperties;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  options?: (string | { label: string; value: string })[];
  dropdownOptionsFilter?: { [key: string]: string };
  presets?: DateRangePreset[];
  presetPivots?: DateRangePresetPivots;
}

const FilterItemContainer = styled.div<{
  flex: string;
  minWidth: number;
  isInMoreFilters?: boolean;
}>`
  flex: ${({ flex, isInMoreFilters }) =>
    isInMoreFilters ? "0 0 30%" : flex} !important;
  margin-right: ${FILTER_ITEMS_GAP}px !important;
  min-width: ${({ minWidth }) => minWidth}px;
`;

const Label = styled.div`
  color: ${colors.neutrals["100"]};
  line-height: 24px;
  font-weight: 500;
`;

const MAIN_COMPONENT_STYLE = {
  width: "100%",
  height: "40px",
};

const Filter = ({
  config,
  queryValues,
  onQueryChange,
  isInMoreFilters,
  dependsOnValues,
}: FilterProps) => {
  const { flexGrow, flexShrink, flexBasis } = config.styleProps;
  const MainComponent = useMemo<LoadableComponent<FilterMainComponentProps>>(
    () => loadable(() => import(`./components/${config.reactComponent}`)),
    [config.reactComponent],
  );
  const { fetchReportOnEnterPress } = config;
  const {
    fetchReport,
    reportConfig: { type },
  } = useReportContext();

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (!fetchReportOnEnterPress) return;
      if (event.key === "Enter") {
        fetchReport();
      }
    },
    [fetchReport, fetchReportOnEnterPress],
  );

  const testId = useMemo(
    () =>
      `${type.toLowerCase().replaceAll("-", "_")}-filter-${typeof config.fields === "string" ? config.fields : config.fields.join("_")}`,
    [config.fields, type],
  );

  return (
    <FilterItemContainer
      flex={`${flexGrow} ${flexShrink} ${flexBasis}px`}
      minWidth={
        config.containerStyle?.minWidth === undefined
          ? config.styleProps.flexBasis - FILTER_ITEMS_GAP
          : parseFloat(config.containerStyle.minWidth + "")
      }
      isInMoreFilters={isInMoreFilters}
      style={{ ...omit(config.containerStyle, "minWidth") }}
      data-testid={testId}
    >
      {config.showLabel &&
        <Label> {config.label} </Label>
      }
      <MainComponent
        values={queryValues}
        onChange={onQueryChange}
        options={config.options}
        isMulti={config.isMulti}
        dependsOnValues={dependsOnValues}
        placeholder={config.placeholder}
        style={MAIN_COMPONENT_STYLE}
        onKeyPress={handleKeyPress}
        dropdownOptionsFilter={config.dropdownOptionsFilter}
        presets={config.presets}
        presetPivots={config.presetPivots}
      />
    </FilterItemContainer>
  );
};

export default Filter;
