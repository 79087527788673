import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createAddTagState, createLoadAccountStatuses, createLoadDepartmentList, createLoadIndustries, createLoadTags, createLoadTeamList, createLoadUserReps } from "../../../actions";
import { createAddClientPhone, createUpdateClient, createUpdateClientPhone } from "../../../actions/client";
import { createClearClientState, createLoadCompanyTermsList, createLoadCompanyUserList, createLoadTaxesList, createLoadParentClientList } from "../../../actions/company_search";
import { getClientTagOptions, getDeptOptions, getOptions } from "../../company-search";
import { ClientDetails } from "../../company-search/ClientDetails";
import { Button, Col, colors, Popup, PopupHeader } from '@commonsku/styles';
import { DisplayClient, DisplayContact } from '../../../types';
import styled from 'styled-components';
import { createNewFeaturePopup } from '../../../actions/popup';
import { getIdentityUtils, oauth, titleCase } from '../../../utils';
import { getClientDetailsState } from '../../../redux/clientDetails';
import { useIdentity } from '../../../hooks';
import useClientDetails from "../hooks/useClientDetails";
import useCompanyCommissionClientRates from "../../admin-new/hooks/useCompanyCommissionClientRates";

const PopupHeaderWrapper = styled(PopupHeader)`
    &&& {
        display: flex;
        flex-wrap: wrap-reverse;
    }
`;

const Title = styled.span`
    font-size: 1.8rem;
    font-weight: 500;
    text-align: left;
    align-self: center;
    padding-top: 3px;
    padding-left: 3px;
    border-bottom: none;
    font-family: skufont-demibold, sans-serif;
    color: ${colors.neutrals[90]};
`;

const ButtonCol = styled(Col)`
    &&& {
        text-align: right;
        align-self: center;
    }
`;

const CloseButton = styled(Button)`
    &&& {
        margin-right: 5px;
    }
`;

interface CompanySearchContact {
    contact_id: string;
    contact_first_name: string;
    contact_last_name: string;
}

interface CompanySearchPhone {
    phone_id: string;
    phone_type: string;
    phone_number: string;
    phone_extension: string;
}

interface CompanySearchClient {
    company_type: "CLIENT",
    client_id?: string;
    client_name?: string;
    client_email?: string;
    client_tags?: string[];
    primary_contact_id?: string;
    contacts: CompanySearchContact[];
    sales_target?: number;
    sales_report?: {
        y1_total?: number;
    },
    client_tenant_account_number?: string;
    client_order_margin_minimum?: number;
    industry_id?: string;
    default_tax_id?: string;
    default_terms_id?: string;
    default_currency_id?: string;
    sales_rep_id?: string;
    account_status_id?: string;
    avalara_entity_use_code?: string;
    parent_client_id?: string;
    qbo_customer_ref?: number | null;
    client_profile?: string | null;
    client_website?: string | null;
    client_facebook?: string | null;
    client_twitter?: string | null;
    commission_client_rate_id?: string | null;
}

const mapDisplayClient = (
    client: DisplayClient,
    contacts: readonly DisplayContact[],
): CompanySearchClient => ({
    company_type: "CLIENT",
    client_id: client.id,
    client_name: client.name,
    client_email: client.email,
    client_tags: client.tags.map(tag => tag.label),
    primary_contact_id: client.primaryContact?.id,
    contacts: contacts.map(contact => ({
      contact_id: contact.id,
      contact_first_name: contact.firstName,
      contact_last_name: contact.lastName,
    })),
    sales_target: client.salesTarget,
    sales_report: {
        y1_total: client.salesToDate,
    },
    client_tenant_account_number: client.tenantAccountNumber,
    client_order_margin_minimum: client.minMargin,
    industry_id: client.industry.id,
    default_tax_id: client.defaultTax.id,
    default_terms_id: client.defaultTerms.id,
    default_currency_id: client.defaultCurrency.id,
    sales_rep_id: client.clientRep.id,
    account_status_id: client.status?.id,
    avalara_entity_use_code: client.avalaraEntityUseCode,
    parent_client_id: client.parentClientId,
    qbo_customer_ref: client.qboCustomerRef,
    client_profile: client.profile,
    client_website: client.website,
    client_facebook: client.facebook,
    client_twitter: client.twitter,
    commission_client_rate_id: client.commissionClientRateId,
});

const onInit = ({ user_id, onCreateNewFeaturePopup, callback }) => {
    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
        if (json.show_popup == 1) {
            onCreateNewFeaturePopup();
        }
    });

    if (callback && typeof callback === 'function') {
        callback();
    }
};

type ClientDetailsPopupProps = any & {
    onClose: () => void;
};

export const ClientDetailsPopup = ({ onClose }: ClientDetailsPopupProps) => {
    const dispatch = useDispatch();
    const identity = useIdentity();
    const { isAdmin, hasCapabilities } = getIdentityUtils(identity);
    const { client, contacts, currencies } = useClientDetails();
    const { editingDetails } = useSelector(getClientDetailsState);
    const [writableClient, setWritableClient] = useState(mapDisplayClient(client, contacts));
    const [writablePhones, setWritablePhones] = useState(
        client.phones.map(phone => {
            const phoneParts = phone.number.split(' ext. ');
            return {
                phone_id: phone.id,
                phone_type: titleCase(phone.type),
                phone_number: phoneParts[0],
                phone_extension: phoneParts[1] ?? '',
            };
        })
    );
    const user_id = identity.user_id;
    const all_tags_labels = useSelector(getClientTagOptions);

    const statuses = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.account_statuses),
        'account_status_id',
        'account_status_name',
    );

    const statuses_all = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.account_statuses),
        'account_status_id',
        'account_status_name',
        false,
        [{ label: 'All', value: '' }, { label: 'None', value: 'null' }],
    );

    const industries = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.industries),
        'industry_id',
        'industry_name',
    );

    const industries_all = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.industries),
        'industry_id',
        'industry_name',
        false,
        { label: 'All', value: '' },
    );

    const reps = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.users),
        'user_id',
        (v) => (v.contact_first_name + ' ' + v.contact_last_name),
    );

    const reps_all = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.users),
        'user_id',
        (v) => (v.contact_first_name + ' ' + v.contact_last_name),
        false,
        [{ label: 'All', value: '' }, { label: 'None', value: 'none' }],
    );

    const terms = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.terms),
        'terms_id',
        'terms_name',
    );

    const terms_all = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.terms),
        'terms_id',
        'terms_name',
        false,
        { label: 'All', value: '' },
    );

    const currencyOptions = currencies.map(currency => ({
        label: currency.id,
        value: currency.id,
    }));

    const taxes = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.taxes),
        'tax_id',
        'label',
    );

    const taxes_all = getOptions(
        // @ts-ignore
        useSelector((s) => s.dropdowns.taxes),
        'tax_id',
        'label',
        false,
        { label: 'All', value: '' },
    );

    const departments = getDeptOptions(
        // @ts-ignore
        useSelector((s) => s),
    );

    const parent_clients = getOptions(
        useSelector(
            (s) => Object.values(
                // @ts-ignore
                (s.entities.company_search ?? {}).parent_clients ?? {}
            )
        ),
        'client_id',
        'client_name',
        [],
        [{ label: 'None', value: '' }],
    );

    const [rates] = useCompanyCommissionClientRates();
    const rateOptions = rates.map(rate => ({
        label: `${rate.client_rate_label} (x${rate.multiplier})`,
        value: rate.commission_client_rate_id,
    }));

    const canEdit = isAdmin() || hasCapabilities(
        ["MODIFY-DOWNSTREAM-ACCOUNT", "CREATE-DOWNSTREAM-ACCOUNT", 'MODIFY-CLIENT']
        , true);

    const filterOptions = {
        all_tags_labels,
        statuses,
        statuses_all,
        industries,
        industries_all,
        reps,
        reps_all,
        terms,
        terms_all,
        currencies: currencyOptions,
        taxes,
        taxes_all,
        departments,
        rates: rateOptions,
    };

    const onCreateNewFeaturePopup = () => {
        dispatch(createNewFeaturePopup());
    };

    useEffect(() => {
        onInit({
            user_id, onCreateNewFeaturePopup, callback: () => {
                dispatch(createLoadAccountStatuses('CLIENT'));
                dispatch(createLoadIndustries());
                dispatch(createLoadTags());
                dispatch(createLoadUserReps(identity.company_id, identity.company_type));
                dispatch(createLoadTaxesList());
                dispatch(createLoadCompanyTermsList());
                dispatch(createLoadCompanyUserList(identity.company_id, identity.company_type, {
                    'max-results': 4294967296,
                    report: true,
                    exclude_default_company_users: true,
                }));
                dispatch(createLoadTeamList({ team_type: 'GROUP' }));
                dispatch(createLoadDepartmentList());
                dispatch(createLoadParentClientList());
            }
        });
    }, []);

    const updateClient = (_, data) => {
        setWritableClient({ ...data });
    };

    const onSave = () => {
        const data = {
            ...writableClient,
            update_remote: true,
        };

        dispatch(createUpdateClient(client.id, data));

        for (const phone of writablePhones) {
            if (client.phones.find(p => p.id === phone.phone_id)) {
                dispatch(createUpdateClientPhone(
                    phone.phone_id,
                    phone.phone_type,
                    phone.phone_number,
                    phone.phone_extension,
                ));
            } else {
                dispatch(createAddClientPhone(
                    client.id,
                    user_id,
                    phone.phone_type,
                    phone.phone_number,
                    phone.phone_extension
                ))
            }
        }

        for (const phone of client.phones) {
            if (!writablePhones.find(p => p.phone_id === phone.id)) {
                dispatch(createUpdateClientPhone(
                    phone.id,
                    phone.type,
                    phone.number,
                    null,
                    0,
                ));
            }
        }

        onClose();
    };

    const addPhone = () => {
        setWritablePhones([
            ...writablePhones,
            {
                phone_id: null,
                phone_type: 'Work',
                phone_number: '',
                phone_extension: '',
            },
        ]);
    }

    const updatePhone = (
        phone: CompanySearchPhone,
        field: keyof CompanySearchPhone,
        value: CompanySearchPhone[keyof CompanySearchPhone],
    ) => {
        const index = writablePhones.findIndex(p => p.phone_id === phone.phone_id);

        if (index === -1) {
            return;
        }

        const newPhones = [...writablePhones];
        newPhones[index] = { ...phone, [field]: value };
        setWritablePhones(newPhones);
    };

    const removePhone = (index: number) => {
        const newPhones = [...writablePhones];
        newPhones.splice(index, 1);
        setWritablePhones(newPhones);
    }

    return (
        <Popup
            header={
                <PopupHeaderWrapper>
                    <Col xs sm={5}>
                        <Title>{editingDetails ? 'Edit Details' : 'Details'}</Title>
                    </Col>
                    <ButtonCol xs sm={7}>
                        <CloseButton onClick={onClose}>Close</CloseButton>
                        {editingDetails && (
                            <Button cta onClick={onSave}>
                                Save
                            </Button>
                        )}
                    </ButtonCol>
                </PopupHeaderWrapper>
            }
        >
            <ClientDetails
                client={writableClient}
                updateClient={updateClient}
                editDetails={editingDetails}
                perms={canEdit}
                identity={identity}
                user_id={identity.user_id}
                parent_clients={parent_clients}
                filterOptions={filterOptions}
                addTag={tag => dispatch(createAddTagState(tag))}
                phones={writablePhones}
                addPhone={addPhone}
                updatePhone={updatePhone}
                removePhone={removePhone}
                clearClient={() => dispatch(createClearClientState())}
            />
        </Popup>
    );
};
