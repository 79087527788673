import React, { useMemo } from "react";
import styled from "styled-components";
import useClientDetails from "../hooks/useClientDetails";
import {
  ReportConfig,
  ReportElementSizes,
  getReportConfig,
} from "../../report/config";
import { EReportTypes } from "../../report/routes/report_types";
import { ReportProvider } from "../../report/report_context";
import { Csku } from "@commonsku/styles";
import ReportTable from "../../report/table/ReportTable";
import Pagination from "../../report/table/Pagination";
import Loading from "../../report/Loading";
import ReportFiltersContainer from "../../report/filters/ReportFiltersContainer";
import { NewProjectPopupButton } from "../../project/NewProjectPopup";
import { SubPageHeader } from "../SubPageHeader";

const DEFAULT_STYLE_CONSTANTS: ReportElementSizes = {
  SIDE_BAR_WIDTH: 192,
  PREFERRED_WINDOW_WIDTH: 1512,
  TABLE_ELEMENTS_GAP: 16,
  SUMMARY_CONTAINER_HEIGHT: 0, // No summary
  TABLE_PAGE_HEADER_HEIGHT: 152, // Header 72 + Subtitle 80
  ROW_HEIGHT: 80,
  TABLE_BOTTOM_MARGIN: 112,
  BULK_ACTION_BAR_HEIGHT: 69,
} as const;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ProjectPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 14px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const NewProjectButton = styled(NewProjectPopupButton)`
  white-space: nowrap;
  margin-left: 8px !important;
`;

const ReportFilters = styled(ReportFiltersContainer)`
  margin-bottom: 0 !important;
  margin-top: 6px !important;

  .report-filter-container-style {
    width: 100% !important;
    align-items: center;
    justify-content: space-between;
  }

  div[data-testid="client_project-filter-project"] {
    div {
      justify-content: flex-start;
    }
  }
`;

const ReportContainer = styled(Csku)`
    position: relative;
`;

export const ProjectsPage = () => {
  const { client } = useClientDetails();

  const reportConfig: ReportConfig = useMemo(
    () => ({ ...getReportConfig(EReportTypes.ClientProject) }),
    []
  );

  const defaultFilters = useMemo(
    () => ({
      client_id: client.id,
    }),
    [client.id]
  );

  return (
    <ProjectPageContainer>
      <SubPageHeader title="Projects" clientName={client.name} />
      <ReportProvider
        reportConfig={reportConfig}
        defaultFilters={defaultFilters}
        elementSizeParameters={DEFAULT_STYLE_CONSTANTS}
      >
        <ProjectContainer>
          <Header>
            <ReportFilters />
            <NewProjectButton
              style={{}}
              isButton
              buttonProps={{ size: "medium" }}
              client_id={client.id}
            />
          </Header>
          <ReportContainer as={"div"}>
            <ReportTable headerClassName="client-project-report-table-header subreport-table-header" />
            <Pagination />
            <Loading />
          </ReportContainer>
        </ProjectContainer>
      </ReportProvider>
    </ProjectPageContainer>
  );
};
